/* eslint-disable react/jsx-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { graphql, navigate, Link } from 'gatsby';
import { URLS } from '../constants/common';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';
import Tag from '../components/shared/tag/tag';

const BlogIndex = props => {
  const { data, location } = props;
  const [windowWidth, setWindowWidth] = React.useState(0);
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  const mainArticleText = "Introduction to hydroponics for growing Marijuana";

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Small breakpoint: 'sm': '640px',
  React.useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" description="Home page for Hacking the Grow" />
      {windowWidth > 639 ? (
        <div className="grid gap-8 grid-cols-2 mt-16">
          <Link to={URLS['hydro']}>
            <div>
              <Img
                className="rounded-md h-72 md:h-80 max-w-lg ml-auto"
                objectFit="contain"
                fluid={data.hb.childImageSharp.fluid}
              />
            </div>
          </Link>
          <div className="flex flex-col space-y-6 lg:space-y-10 items-baseline max-w-lg mr-auto">
            <h1 className="text-3xl lg:text-4xl font-light">
              {mainArticleText}
            </h1>
            <div className="flex flex-row space-x-4">
              <Tag text="basics" />
              <Tag text="hydroponics" />
            </div>
            <div className="text-base font-light">
              Basics for growing cannabis using hydroponics, including typical
              pitfalls for beginners and how to avoid them.
            </div>
            <Link to={URLS['hydro']}>
              <div className="mt-0 cursor-pointer text-base bg-htgGreen-light hover:bg-htgGreen-dark font-light py-2 px-4 rounded inline">
                Read Now
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <div className="relative max-w-lg mt-8">
          <div>
            <Img
              className="rounded-md h-72 md:h-80 max-w-lg"
              objectFit="contain"
              fluid={data.hb.childImageSharp.fluid}
            />
          </div>
          <div className="absolute w-full bottom-0 bg-black opacity-50 round-md" />
          <div className="absolute top-0">
            <div className="flex flex-col space-y-4 items-center p-4">
              <div className="text-3xl lg:text-4xl font-light text-white">
                {mainArticleText}
              </div>
              <div className="flex flex-row space-x-4">
                <Tag text="basics" />
                <Tag text="hydroponics" />
              </div>
              <div className="text-white text-sm lg:text-base font-light items-center p-4">
                Basics for growing cannabis using hydroponics, including typical
                pitfalls for beginners and how to avoid them.
              </div>
              <Link to={URLS['hydro']}>
                <div className="cursor-pointer text-sm bg-htgGreen-light hover:bg-htgGreen-dark font-light py-2 px-4 rounded inline">
                  Read Now
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="hidden sm:block text-2xl xl:text-3xl my-12 font-light">
        Featured Posts
      </div>
      <div className="hidden sm:flex flex-row space-x-4 lg:space-x-8">
        { // Get the first three posts (most popular)
         posts.slice(posts.length-3, posts.length).map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <div
              role="button"
              className="h-full w-full flex flex-col space-y-2 cursor-pointer"
              onClick={() => {
                navigate(node.fields.slug);
              }}
              onKeyPress={() => {
                navigate(node.fields.slug);
              }}
              tabIndex={0}
            >
              <div>
                <Img
                  className="rounded-md"
                  style={{ height: '12em' }}
                  objectFit="contain"
                  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                />
              </div>
              <div className="flex flex-row space-x-2 justify-end items-center">
                <div className="mr-auto text-xs lg:text-sm font-thin">
                  {node.frontmatter.date}
                </div>
                <Tag text={node.frontmatter.tags[0]} />
                <Tag text={node.frontmatter.tags[1]} />
              </div>
              <div className="text-sm lg:text-base xl:text-lg font-semibold">
                {title}
              </div>
              <div
                className="font-light text-xs lg:text-sm xl:text-base leading-5"
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt
                }}
              />
            </div>
          );
        })}
      </div>
      <hr className="hidden sm:block my-16 opacity-20 border-0 bg-gray-500 text-gray-500 h-px" />
      <div className="my-12 sm:my-0 sm:mb-12 text-2xl xl:text-3xl font-light">
        Latest Posts
      </div>
      {windowWidth > 639 ? (
        <div className="flex flex-col space-y-4 lg:space-y-8 max-w-lg lg:max-w-xl xl:max-w-3xl m-auto">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <div
                role="button"
                className="h-full w-full flex flex-row space-x-2 cursor-pointer"
                onClick={() => {
                  navigate(node.fields.slug);
                }}
                onKeyPress={() => {
                  navigate(node.fields.slug);
                }}
                tabIndex={0}
              >
                <div>
                  <Img
                    className="rounded-md"
                    style={
                      windowWidth > 1023
                        ? { height: '14em', width: '20em' }
                        : { height: '12em', width: '16em' }
                    }
                    objectFit="contain"
                    fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                  />
                </div>
                <div className="relative">
                  <div className="flex flex-row space-x-4 justify-start items-center">
                    {node.frontmatter.tags.map(text => (
                      <Tag text={text} />
                    ))}
                  </div>
                  <div className="text-sm lg:text-base xl:text-lg font-semibold my-2">
                    {title}
                  </div>
                  <div
                    className="font-light text-xs mt-4 lg:text-sm xl:text-base leading-5"
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt
                    }}
                  />
                  <div className="absolute bottom-2 w-full">
                    <div className="flex flex-row space-x-4 justify-start items-center">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg> */}
                      <div className="ml-auto text-xs lg:text-sm font-thin">
                        {node.frontmatter.date}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col space-y-4">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <div
                className="cursor-pointer relative"
                onClick={() => {
                  navigate(node.fields.slug);
                }}
                onKeyPress={() => {
                  navigate(node.fields.slug);
                }}
                tabIndex={0}
                label={node.fields.slug}
                role="button"
              >
                <Img
                  className="rounded-md h-48"
                  objectFit="contain"
                  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                />
                <div className="absolute w-full h-full bottom-0 bg-black opacity-50 rounded-md" />
                <div className="absolute top-0 p-4">
                  <div className="flex flex-row space-x-4 justify-start items-center">
                    {node.frontmatter.tags.map(text => (
                      <Tag text={text} />
                    ))}
                  </div>
                  <div className="text-sm font-medium my-2 text-white">
                    {title}
                  </div>
                  <div
                    className="font-light text-white text-xs mt-4 leading-5"
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Layout>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    hb: file(relativePath: { eq: "hb-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
